import { FC } from 'react';
import IconProps from './IconProps';

const MailClosedIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5547 3.16799C1.09517 2.86164 0.474302 2.98581 0.16795 3.44534C-0.138403 3.90487 -0.0142288 4.52574 0.4453 4.83209L1.5547 3.16799ZM8.8906 9.26044L9.44533 8.42841L9.4453 8.42839L8.8906 9.26044ZM11.1094 9.26044L10.5547 8.42839L10.5547 8.42841L11.1094 9.26044ZM19.5547 4.83209C20.0142 4.52574 20.1384 3.90487 19.8321 3.44534C19.5257 2.98581 18.9048 2.86164 18.4453 3.16799L19.5547 4.83209ZM0.4453 4.83209L8.3359 10.0925L9.4453 8.42839L1.5547 3.16799L0.4453 4.83209ZM8.33587 10.0925C9.34359 10.7643 10.6564 10.7643 11.6641 10.0925L10.5547 8.42841C10.2188 8.65235 9.78122 8.65235 9.44533 8.42841L8.33587 10.0925ZM11.6641 10.0925L19.5547 4.83209L18.4453 3.16799L10.5547 8.42839L11.6641 10.0925ZM3.00004 16H17V14H3.00004V16ZM17 16C18.6569 16 20 14.6569 20 13H18C18 13.5523 17.5524 14 17 14V16ZM20 13V3H18V13H20ZM20 3C20 1.34315 18.6569 0 17 0V2C17.5523 2 18 2.44771 18 3H20ZM17 0H3.00004V2H17V0ZM3.00004 0C1.34318 0 3.8147e-05 1.34315 3.8147e-05 3H2.00004C2.00004 2.44771 2.44775 2 3.00004 2V0ZM3.8147e-05 3V13H2.00004V3H3.8147e-05ZM3.8147e-05 13C3.8147e-05 14.6569 1.34319 16 3.00004 16V14C2.44774 14 2.00004 13.5523 2.00004 13H3.8147e-05Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MailClosedIcon;
